import {NgModule} from '@angular/core';
import {RouterLinkInNewTabDirective} from './router-link-in-new-tab.directive';
import {RouterModule} from '@angular/router';

@NgModule({
	declarations: [RouterLinkInNewTabDirective],
	imports: [RouterModule],
	exports: [RouterLinkInNewTabDirective]
})
export class RouterLinkInNewTabModule {
}
